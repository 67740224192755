import Iframe from "react-iframe";
import "./App.css";
import React from "react";
import { Helmet } from "react-helmet";

class App extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://videos.theskill.store/index.js";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);

    const style = document.createElement("link");
    style.rel = "stylesheet";
    style.type = "text/css";
    style.href = "https://videos.theskill.store/index.css";
    document.head.appendChild(style);
  }

  render() {
    return (
      <div className="container">
        <Iframe url="https://canopus.com.br/?portfolio=true" width="100%" />
        <Helmet>
          <script
            src="https://videos.theskill.store/index.js"
            type="text/javascript"
            async
          />
          <link
            href="https://videos.theskill.store/index.css"
            rel="stylesheet"
            type="text/css"
          />
        </Helmet>
      </div>
    );
  }
}

export default App;